/* =============================================================================
   App specific CSS file.
   ========================================================================== */

html {
  overflow-y: scroll;
}

body {
  padding-top: 60px;
  font-size: 1rem;
  font-weight: 400;
  font-family:
    Knockout,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}

section {
  overflow: auto;
}

textarea {
  resize: vertical;
}

.container-narrow {
  margin: 0 auto;
  max-width: 700px;
}

/* Forms */

.form-register {
  width: 50%;
}

.form-register .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* footer */

footer {
  margin-top: 45px;
  padding-top: 5px;
  border-top: 1px solid #eaeaea;
  color: #999999;
}

footer a {
  color: #999999;
}

footer p {
  float: right;
  margin-right: 25px;
}

footer ul {
  list-style: none;
}

footer ul li {
  float: left;
  margin-left: 10px;
}

footer .company {
  float: left;
  margin-left: 25px;
}

footer .footer-nav {
  float: right;
  margin-right: 25px;
  list-style: none;
}

.table-options-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td,
table.dataTable tbody th,
table.dataTable tbody td,
table.dataTable td.dt-type-numeric,
table.dataTable th.dt-type-numeric {
  text-align: center;
}

.no-border-left {
  border-left-width: 0 !important;
}

#table-container {
  padding: 0.5em;
}

.red-profile {
  background-color: #a52834;
}

.blue-profile {
  background-color: #25cff2;
}

.grey-profile {
  background-color: #a9aaae;
}

.profile-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 90%;
  min-height: 90%;
  border: 1px solid #000;
  border-radius: 4px;
}

.profile-box-container {
  position: relative;
}

.profiles-table-container {
  overflow-x: auto;
}

.navbar-brand {
  width: 320px;
  left: 15px;
  position: relative;
}

.nav-item a {
  color: #fff;
  text-transform: uppercase;
  padding: 0.1rem 0.95rem;
}

.image-column {
  float: right;
  max-width: 700px;
}
